const PATHS = {
  HOMEPAGE: "/",
  CREATE_COC: "/coc/create",
  EDIT_COC: "/coc/edit/:id/:version",
  LIST_COC: "/cocs/:type",
  ACCOUNT: "/account",
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password",
  SAMPLE_SCHEDULING: "/sample-scheduling",
  COC_LIST_SOIL: "/cocs/soil",
  COC_LIST_LIQUID: "/cocs/liquid",
  COC_LIST_GAS: "/cocs/gas",
  PHOTO_LIST: "/photo",
  RESULT_TABLE_COC: "/summary-table-level-logger-coc",
  RESULT_TABLE_COC_DETAIL: "/summary-table-level-logger-coc/:id/:version",
  RESULT_TABLE_NO_COC: "/summary-table-level-logger-no-coc",
  GAS_SCHEDULING: "/gas",
  FIELD_OBSERVATIONS: "/field-observations",
  MAP: "/map",
  GROUNDWATER_LEVEL: "/ground-water-level",
  UPLOAD_DATA: "/upload-data",
  SUMMARY_TABLE: "/summary-table",
  AQUIFER_TESTING: "/aquifer-testing",
  LANDFILL_GAS_MONITORING: "/landfill-gas-monitoring",
  ASBESTOS_BILDING_SURVEY: "/asbestos-building-survey",
  SCHEDULE_FIELD_WORK: "/level-logger",
  FIELD_DATA: "/field-data-level-logger/:id/:type",
  LEVEL_LOGGER_DATA: "/level-logger-data",
  MASTER_TABLE: "/level-logger-transform-tables-graphs/:id/:typeData",
  RAIN_FALL: "/rainfall-data",
  RESULT_TABLE: "/summary-table-level-logger",
  GIS_MAP: "/map",
  LEVEL_LOGGER: "/level-logger-data/:id/:typeData",
  RAIN_FALL_DETAIL: "/rainfall-data/:id",
  WATER_QUALITY: "/water-quality",
  GENERATE_COC: "/generate-coc-water-quality",
  DETAIL_COC: "/detail-coc-water-quality-create",
  DETAIL_COC_ID: "/data-coc-water-quality-detail/:id/:version",
  PHOTO_LIST_WQ: "/photo-list-water-quality",
  WATER_QUALITY_COC: "/wq-coc",
  WATER_QUALITY_WITHOUT_COC: "/wq-without-coc",
  WATER_QUALITY_RESULT_TABLE_COC: "/wq-result-table-coc/:id/:version/:labName",
  FIELD_SHEET_AND_PARAMETERS: "/field-sheets-and-parameters",
  MANUAL_DIP: "/dip-meter-reading",
  FIELD_SHEET_DETAIL: "/field-data-dip-meter-reading/:id",
  MASTER_TABLE_MANUAL: "/dip-meter-reading-transform-tables-graphs/:id",
  RESULT_TABLE_MANUAL_DIP: "/summary-table-dip-meter-reading",
  ALL_MAP: "/all-map",
  WELL_LIST: '/well-data', 
  WELL_MANUAL_UPLOAD: '/well-upload-data', 
  WELL_PHOTO: '/well-photo',
  GENERATE_WELL_TABLE: '/well-data/well-result-table',
  
  VI_NOTES: 'vi-notes',
  VI_PHOTOS: 'vi-photos'
};

export default PATHS;
