import { lazy, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { getToken } from "helpers/webStorage";
import PATHS from "./const";
import { useDispatch } from "react-redux";
import { setCurrentRoute } from "store/app";
import { Auth, Hub } from "aws-amplify";
import { setRefreshToken, setToken } from "helpers/webStorage";
import { setIsLoadingAzure } from "store/auth"; 

const Home = lazy(() => import("pages/Welcome"));
const SampleScheduling = lazy(() => import("pages/SampleProcessing"));
const CreateCOCForm = lazy(() => import("pages/GenerateCOC/CreateCOCForm"));
const EditCOCForm = lazy(() => import("pages/GenerateCOC/EditCOCForm"));
const COCList = lazy(() => import("pages/COCList"));
const MyAccount = lazy(() => import("pages/MyAccount"));
const Login = lazy(() => import("pages/Auth/Login"));
const ResetPassword = lazy(() => import("pages/Auth/FirstTimeResetPassword"));
const ForgotPassword = lazy(() => import("pages/Auth/ForgotPassword"));
const PhotoList = lazy(() => import("pages/PhotoList"));
const ResultTableWithoutCOC = lazy(() => import("pages/ResultTableWithoutCOC"));
const Map = lazy(() => import("pages/Map"));
const ResultTableCOC = lazy(() => import("pages/ResultTableCOC"));
const ResultTableCOCDetail = lazy(() => import("pages/ResultTableCOCDetail"));
const FieldObservations = lazy(() => import("pages/FieldObservations"));
const ScheduleFieldWork = lazy(
  () => import("pages/GroundWater/GroundWaterLevel/ScheduleFieldWork")
);
const FieldData = lazy(
  () => import("pages/GroundWater/GroundWaterLevel/FieldData")
);
const LevelLoggerData = lazy(
  () => import("pages/GroundWater/GroundWaterLevel/LevelLoggerData")
);
const MasterTable = lazy(
  () => import("pages/GroundWater/GroundWaterLevel/MasterTable")
);
const GISMap = lazy(() => import("pages/GroundWater/GroundWaterLevel/GISMap"));
const ResultTable = lazy(
  () => import("pages/GroundWater/GroundWaterLevel/ResultTable")
);
const TablePiezometer = lazy(
  () =>
    import(
      "pages/GroundWater/GroundWaterLevel/LevelLoggerData/components/TablePiezometer"
    )
);
const TablePiezometerRainFall = lazy(
  () =>
    import(
      "pages/GroundWater/GroundWaterLevel/RainFail/components/TablePiezometer"
    )
);
const RainFall = lazy(
  () => import("pages/GroundWater/GroundWaterLevel/RainFail")
);
const FieldSheetWaterQuality = lazy(
  () => import("pages/WaterQuanlity/FieldSheet")
);

const GenerateCOC = lazy(() => import("pages/WaterQuanlity/GenerateCOC"));
const DetailCOC = lazy(
  () => import("pages/WaterQuanlity/GenerateCOC/components/DetailCOC")
);
const PhotoListWQ = lazy(() => import("pages/WaterQuanlity/PhotoList"));
const ResultTableWithCOC = lazy(
  () => import("pages/WaterQuanlity/ResultTableWithCOC")
);
const WQResultTableWithoutCOC = lazy(
  () => import("pages/WaterQuanlity/WQResultTableWithoutCOC")
);
const WQResultTable = lazy(
  () => import("pages/WaterQuanlity/ResultTableWithCOC/components/ResultTable")
);

const FieldSheetParameters = lazy(
  () => import("pages/WaterQuanlity/FieldSheedParameters")
);

const ManualDip = lazy(
  () => import("pages/GroundWater/ManualDip/ManualDipDetail")
);

const FieldSheetDetail = lazy(
  () => import("pages/GroundWater/ManualDip/FieldSheetDetail")
);

const MasterTableManual = lazy(
  () => import("pages/GroundWater/ManualDip/MasterTable")
);

const ResultTableDetail = lazy(
  () => import("pages/GroundWater/ManualDip/ResultTable")
);

const WellList = lazy(
  () => import("pages/LandfillGas/WellData/WellList")
);

const UploadWellData = lazy(
  () => import("pages/LandfillGas/WellData/UploadWellData")
);

const WellPhoto = lazy(
  () => import("pages/LandfillGas/WellData/PhotoList")
); 

const GenerateWellTable = lazy(
  () => import("pages/LandfillGas/WellData/WellList/GenerateWellTable")
);
  
/**
 * Declare type route
 */
type routeItem = {
  path: string;
  title: string;
  key: string;
  exact: boolean;
  component: Function;
  requiredAuth: boolean;
  name: string;
  computedMatch?: any;
};

/**
 * Declare type routes
 */
type routes = routeItem & {
  routes?: routeItem[];
};

/**
 * Declare routes
 */
const ROUTES: routes[] = [
  {
    path: PATHS.HOMEPAGE,
    title: "WEKA",
    key: "ROOT",
    exact: true,
    component: Home,
    routes: [],
    requiredAuth: true,
    name: "homepage",
  },
  {
    path: PATHS.SAMPLE_SCHEDULING,
    title: "WEKA | Sample Scheduling",
    key: "ROOT",
    exact: true,
    component: SampleScheduling,
    routes: [],
    requiredAuth: true,
    name: "sample-scheduling",
  },
  {
    path: PATHS.CREATE_COC,
    title: "WEKA | Create COC",
    key: "ROOT",
    exact: true,
    component: CreateCOCForm,
    routes: [],
    requiredAuth: true,
    name: "create-coc",
  },
  {
    path: PATHS.EDIT_COC,
    title: "WEKA | Edit COC",
    key: "ROOT",
    exact: true,
    component: EditCOCForm,
    routes: [],
    requiredAuth: true,
    name: "edit-coc",
  },
  {
    path: PATHS.LIST_COC,
    title: "WEKA | List COC",
    key: "ROOT",
    exact: true,
    component: COCList,
    routes: [],
    requiredAuth: true,
    name: "list-coc",
  },
  {
    path: PATHS.ACCOUNT,
    title: "WEKA | My account",
    key: "ROOT",
    exact: true,
    component: MyAccount,
    routes: [],
    requiredAuth: true,
    name: "myaccount",
  },
  {
    path: PATHS.LOGIN,
    title: "WEKA | Login",
    key: "ROOT",
    exact: true,
    component: Login,
    routes: [],
    requiredAuth: false,
    name: "login",
  },
  {
    path: PATHS.RESET_PASSWORD,
    title: "WEKA | Reset Password",
    key: "ROOT",
    exact: true,
    component: ResetPassword,
    routes: [],
    requiredAuth: false,
    name: "reset-password",
  },
  {
    path: PATHS.FORGOT_PASSWORD,
    title: "WEKA | Forgot Password",
    key: "ROOT",
    exact: true,
    component: ForgotPassword,
    routes: [],
    requiredAuth: false,
    name: "forgot-password",
  },
  {
    path: PATHS.PHOTO_LIST,
    title: "WEKA | Photo List",
    key: "ROOT",
    exact: true,
    component: PhotoList,
    routes: [],
    requiredAuth: false,
    name: "photo-list",
  },
  {
    path: PATHS.FIELD_OBSERVATIONS,
    title: "WEKA | Field Observations",
    key: "ROOT",
    exact: true,
    component: FieldObservations,
    routes: [],
    requiredAuth: false,
    name: "field-observations",
  },
  {
    path: PATHS.WATER_QUALITY,
    title: "WEKA | Water Quality",
    key: "ROOT",
    exact: true,
    component: FieldSheetWaterQuality,
    routes: [],
    requiredAuth: false,
    name: "field-observations",
  },
  {
    path: PATHS.GENERATE_COC,
    title: "WEKA | Generate COC",
    key: "ROOT",
    exact: true,
    component: GenerateCOC,
    routes: [],
    requiredAuth: false,
    name: "field-observations",
  },
  {
    path: PATHS.DETAIL_COC,
    title: "WEKA | Generate COC",
    key: "ROOT",
    exact: true,
    component: DetailCOC,
    routes: [],
    requiredAuth: false,
    name: "field-observations",
  },
  {
    path: PATHS.WATER_QUALITY_COC,
    title: "WEKA |WQ Result Table (With CoC)",
    key: "ROOT",
    exact: true,
    component: ResultTableWithCOC,
    routes: [],
    requiredAuth: true,
    name: "wq-coc-detail",
  },
  {
    path: PATHS.WATER_QUALITY_WITHOUT_COC,
    title: "WEKA |WQ Result Table (Without CoC)",
    key: "ROOT",
    exact: true,
    component: WQResultTableWithoutCOC,
    routes: [],
    requiredAuth: false,
    name: "wq-no-coc",
  },
  {
    path: PATHS.WATER_QUALITY_RESULT_TABLE_COC,
    title: "WEKA |WQ Result Table (With CoC)",
    key: "ROOT",
    exact: true,
    component: WQResultTable,
    routes: [],
    requiredAuth: true,
    name: "wq-result-table-coc-detail",
  },
  {
    path: PATHS.DETAIL_COC_ID,
    title: "WEKA | Generate COC",
    key: "ROOT",
    exact: true,
    component: DetailCOC,
    routes: [],
    requiredAuth: false,
    name: "field-observations",
  },
  {
    path: PATHS.RESULT_TABLE_COC,
    title: "WEKA | Result Table (With CoC)",
    key: "ROOT",
    exact: true,
    component: ResultTableCOC,
    routes: [],
    requiredAuth: false,
    name: "result-table-coc",
  },
  {
    path: PATHS.RESULT_TABLE_COC_DETAIL,
    title: "WEKA | Result Table (With CoC)",
    key: "ROOT",
    exact: true,
    component: ResultTableCOCDetail,
    routes: [],
    requiredAuth: true,
    name: "result-table-coc-detail",
  },
  {
    path: PATHS.RESULT_TABLE_NO_COC,
    title: "WEKA | Result Table (Without CoC)",
    key: "ROOT",
    exact: true,
    component: ResultTableWithoutCOC,
    routes: [],
    requiredAuth: false,
    name: "result-table-no-coc",
  },
  {
    path: PATHS.MAP,
    title: "WEKA | Map",
    key: "ROOT",
    exact: true,
    component: Map,
    routes: [],
    requiredAuth: false,
    name: "map",
  },
  {
    path: PATHS.SCHEDULE_FIELD_WORK,
    title: "WEKA | Schedule Piezometers",
    key: "ROOT",
    exact: true,
    component: ScheduleFieldWork,
    routes: [],
    requiredAuth: false,
    name: "schedule-field-work",
  },
  {
    path: PATHS.MANUAL_DIP,
    title: "WEKA | Manual Dip",
    key: "ROOT",
    exact: true,
    component: ManualDip,
    routes: [],
    requiredAuth: false,
    name: "manual-dip",
  },
  {
    path: PATHS.FIELD_SHEET_DETAIL,
    title: "WEKA | Field Sheet Detail",
    key: "ROOT",
    exact: true,
    component: FieldSheetDetail,
    routes: [],
    requiredAuth: false,
    name: "field-sheet-detail",
  },
  {
    path: PATHS.RESULT_TABLE_MANUAL_DIP,
    title: "WEKA | Summary Table Manual Dip",
    key: "ROOT",
    exact: true,
    component: ResultTableDetail,
    routes: [],
    requiredAuth: false,
    name: "summary-table-manual-dip",
  },
  {
    path: PATHS.FIELD_SHEET_AND_PARAMETERS,
    title: "WEKA | Field Sheet Parameters",
    key: "ROOT",
    exact: true,
    component: FieldSheetParameters,
    routes: [],
    requiredAuth: false,
    name: "field-data",
  },
  {
    path: PATHS.MASTER_TABLE_MANUAL,
    title: "WEKA | Master Table",
    key: "ROOT",
    exact: true,
    component: MasterTableManual,
    routes: [],
    requiredAuth: false,
    name: "master-table-manual",
  },
  {
    path: PATHS.FIELD_DATA,
    title: "WEKA | Field Data",
    key: "ROOT",
    exact: true,
    component: FieldData,
    routes: [],
    requiredAuth: false,
    name: "field-data",
  },
  {
    path: PATHS.PHOTO_LIST_WQ,
    title: "WEKA | Photo List",
    key: "ROOT",
    exact: true,
    component: PhotoListWQ,
    routes: [],
    requiredAuth: false,
    name: "field-data",
  },
  {
    path: PATHS.LEVEL_LOGGER_DATA,
    title: "WEKA | Level Logger Data",
    key: "ROOT",
    exact: true,
    component: LevelLoggerData,
    routes: [],
    requiredAuth: false,
    name: "level-logger-data",
  },
  {
    path: PATHS.LEVEL_LOGGER,
    title: "WEKA | Level Logger Data",
    key: "ROOT",
    exact: true,
    component: TablePiezometer,
    routes: [],
    requiredAuth: false,
    name: "level-logger-data-child",
  },
  {
    path: PATHS.RAIN_FALL_DETAIL,
    title: "WEKA | Rainfall Data ",
    key: "ROOT",
    exact: true,
    component: TablePiezometerRainFall,
    routes: [],
    requiredAuth: false,
    name: "level-logger-data-child",
  },
  {
    path: PATHS.RAIN_FALL,
    title: "WEKA | Rainfall Data ",
    key: "ROOT",
    exact: true,
    component: RainFall,
    routes: [],
    requiredAuth: false,
    name: "level-logger-data",
  },
  {
    path: PATHS.MASTER_TABLE,
    title: "WEKA | Master Table",
    key: "ROOT",
    exact: true,
    component: MasterTable,
    routes: [],
    requiredAuth: false,
    name: "master-table",
  },
  {
    path: PATHS.RESULT_TABLE,
    title: "WEKA | Summary Table",
    key: "ROOT",
    exact: true,
    component: ResultTable,
    routes: [],
    requiredAuth: false,
    name: "results-table",
  },
  {
    path: PATHS.GIS_MAP,
    title: "WEKA | GIS Map",
    key: "ROOT",
    exact: true,
    component: GISMap,
    routes: [],
    requiredAuth: false,
    name: "map",
  }, 
  {
    path: PATHS.WELL_LIST,
    title: "WEKA | Well Data",
    key: "ROOT",
    exact: true,
    component: WellList,
    routes: [],
    requiredAuth: false,
    name: "well data",
  },
  {
    path: PATHS.WELL_MANUAL_UPLOAD,
    title: "WEKA | Upload Well Data",
    key: "ROOT",
    exact: true,
    component: UploadWellData,
    routes: [],
    requiredAuth: false,
    name: "upload well data",
  },
  {
    path: PATHS.WELL_PHOTO,
    title: "WEKA | Well Photo",
    key: "ROOT",
    exact: true,
    component: WellPhoto,
    routes: [],
    requiredAuth: false,
    name: "well photo",
  },
  {
    path: PATHS.GENERATE_WELL_TABLE,
    title: "WEKA | Well Result Table",
    key: "ROOT",
    exact: true,
    component: GenerateWellTable,
    routes: [],
    requiredAuth: false,
    name: "well photo",
  },
];

export default ROUTES;

/**
 * Function render routes
 * @param {Array} route
 */
export function RenderRoutes({ routes }: { routes: routes[] }) {
  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes {...route} />;
      })}

      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  );
}

/**
 * Function render sub routes
 * @param {Array} route
 */
function RouteWithSubRoutes(route: routes) {
  const history = useHistory();
  document.title = route.title;
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          setTimeout(() => {
            dispatch(setIsLoadingAzure(true));
          }, 50);
          setAccessToken(data);
          break;
        default:
          dispatch(setIsLoadingAzure(false));
      }
    });
    return unsubscribe;
    // eslint-disable-next-line
  }, []);

  /**
   * Set user, token and redirect to home page
   * @async
   * @param {Object} data
   */
  async function setAccessToken(data) {
    const currentSession = await Auth.currentSession();
    let token = await currentSession.getIdToken().getJwtToken();
    let refreshToken = await currentSession.getRefreshToken().getToken();
    await localStorage.setItem(
      "user",
      JSON.stringify({ username: data.username })
    );
    await setToken(token, false);
    await setRefreshToken(refreshToken, false);
    window.location.href = "/";
    dispatch(setIsLoadingAzure(false));
  }

  return (
    <Route
      key={route.key}
      path={route.path}
      exact={route.exact}
      render={(props) => {
        if (props.match.path.indexOf("/:id") === -1) {
          dispatch(setCurrentRoute(props.match.url));
          localStorage.setItem(
            "CURRENT_ROUTE",
            JSON.stringify(props.match.url)
          );
        }
        if (!getToken()) {
          route.requiredAuth && history.push("/login");
        } else if (
          ["/login", "/register", "/forgot-password"].includes(route.path)
        ) {
          history.push("/");
        }
        return <route.component {...props} routes={route.routes} />;
      }}
    />
  );
}
